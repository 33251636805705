<template>
  <router-view v-slot="{ Component }" :key="$route.fullPath">
    <transition mode="out-in"
                :enter-active-class="routerTransitionClassIn"
                :leave-active-class="routerTransitionClassOut">
      <component :class="routerTransitionClassIn"
                 :key="$route.fullPath"
                 :is="Component" />
    </transition>
  </router-view>


</template>

<script>
export default {
  props: {
    localeSlug: {
      type: String,
      default: config.locale.defaultLocale,
    },
  },
  data() {
    return {
      enableRouteTransitionAnimation: true,
    };
  },
  computed: {
    routerTransitionClassIn() {
      return this.enableRouteTransitionAnimation ? config.style.pageTransitionClassIn : "";
    },
    routerTransitionClassOut() {
      return this.enableRouteTransitionAnimation ? config.style.pageTransitionClassOut : "";
    },
  }
};
</script>
